<template>
  <section class="hero is-large">
    <section class="hero-body">
      <article class="hero-banner">
        <section class="title-container">
          <h1 class="title is-3">
            <span class="no-break">ZAOPATRZENIE TECHNICZNE</span>
            <span class="is-font-lighter no-break">TO NASZA SPECJALNOŚĆ</span>
          </h1>
        </section>
        <a @click="scrollToAssortment()" class="hero-banner-button">
          SPRAWDŹ NASZ ASORTYMENT
        </a>
      </article>
    </section>
    <v-lazy-image
      width="1440"
      height="900"
      class="banner-image"
      :src="require(`@/assets/images/techniker-background.webp`)"
      :alt="'banner'"
    />
  </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'Hero',
  components: {
    VLazyImage,
  },
  methods: {
    async scrollToAssortment() {
      smoothscroll.polyfill();
      this.isOpen = false;
      const element = document.getElementById('assortment');
      element.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-image {
  position: absolute;
  max-width: 100%;
  height: auto;
  max-height: 700px;
  object-fit: cover;
  object-position: 50% 50%;
  z-index: 0;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .hero-body {
    z-index: 1;
    min-height: 450px;
  }

  .hero-banner {
    display: none;
  }

  .banner-image {
    height: 100%;
  }
}

@media only screen and (min-width: 769px) {
  .hero-body {
    width: 80%;
    margin: 0 auto;
    min-height: 700px;
    padding: 12rem 1.25rem !important;
    z-index: 1;
  }

  .hero-banner {
    display: flex;
    width: 100%;
    max-width: 525px;
    height: 100%;
    max-height: 300px;
    background-color: #fff;
    margin: 0 0 0 auto;
    flex-direction: column;
  }

  .hero-banner-button {
    width: 60%;
    background-color: $primary;
    margin: auto 0 0 auto;
    padding: 1rem;
    display: inline-flex;
    height: 60px;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: $grey-lighter;
    text-align: right;
  }
}

.title-container {
  padding: 2rem;
  text-align: left;
  position: relative;
  line-height: 1.5;
}

.hero {
  position: relative;
  align-items: center;
  background-color: #fafafa;
}

.no-break {
  white-space: nowrap;
}

.is-font-lighter {
  font-weight: lighter;
}
</style>
